
export default {
  data() {
    return {
      currentView: 'login'
    }
  },
  methods: {
    async goToLogin() {
      await this.$store.dispatch('router/setBacklink', {
        name: this.getRouteBaseName(),
        params: this.$route.params
      })
      this.$router.push(this.localePath({ name: 'login' }))
    }
  }
}
